// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OqvLpyrSC4AsGYMcuCx_ {\r\n  background: linear-gradient(\r\n    245.22deg,\r\n    #c200fb 7.97%,\r\n    #3772ff 49.17%,\r\n    #3773fe 0,\r\n    #5ac4be 92.1%\r\n  );\r\n  border-radius: 5px;\r\n  padding: 1px;\r\n}\r\n.apvIWA9cN3ZqE5UhTiZw {\r\n  background-color: #131a35;\r\n  padding: 30px;\r\n  border-radius: 5px;\r\n  opacity: 0.9;\r\n}\r\n\r\n/* Sm */\r\n@media only screen and (max-width: 600px) {\r\n  .apvIWA9cN3ZqE5UhTiZw {\r\n    padding: 20px;\r\n  }\r\n}\r\n\r\n/* XS */\r\n@media only screen and (max-width: 400px) {\r\n  .apvIWA9cN3ZqE5UhTiZw {\r\n    padding: 16px;\r\n  }\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/CustomCard/CustomCard.module.css"],"names":[],"mappings":"AAAA;EACE;;;;;;GAMC;EACD,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,YAAY;AACd;;AAEA,OAAO;AACP;EACE;IACE,aAAa;EACf;AACF;;AAEA,OAAO;AACP;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".root {\r\n  background: linear-gradient(\r\n    245.22deg,\r\n    #c200fb 7.97%,\r\n    #3772ff 49.17%,\r\n    #3773fe 0,\r\n    #5ac4be 92.1%\r\n  );\r\n  border-radius: 5px;\r\n  padding: 1px;\r\n}\r\n.body {\r\n  background-color: #131a35;\r\n  padding: 30px;\r\n  border-radius: 5px;\r\n  opacity: 0.9;\r\n}\r\n\r\n/* Sm */\r\n@media only screen and (max-width: 600px) {\r\n  .body {\r\n    padding: 20px;\r\n  }\r\n}\r\n\r\n/* XS */\r\n@media only screen and (max-width: 400px) {\r\n  .body {\r\n    padding: 16px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "OqvLpyrSC4AsGYMcuCx_",
	"body": "apvIWA9cN3ZqE5UhTiZw"
};
export default ___CSS_LOADER_EXPORT___;
